import { graphql } from 'gatsby';
import React from 'react';
import styled from '@emotion/styled';

import SEO from '../components/seo';
import { Layout, Wrapper } from 'components/layout';
import { List as BlogPostList } from 'components/blogPostList';

const AboutText = styled.p`
  color: hsla(0, 0%, 0%, 0.8);
  margin-top: -1rem;
  margin-bottom: 2.499rem;
`;

export default ({ data }) => {
  const {
    posts,
  } = data;

  return (
    <Layout>
      <SEO
        path="/blog/"
        title={"Blog"}
      />
      <Wrapper>
        <h1>Blog</h1>
        <AboutText>
          Thoughts about React.js, Node.js, Kubernetes and other interesting things.
        </AboutText>
        <BlogPostList posts={posts}/>
      </Wrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query Blog {
    posts: allMarkdownRemark(
      filter: { fields: { visible: { eq: true } } }
      sort: { fields: [frontmatter___date] order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            excerpt
            url
          }
          fields {
            slug
            status
            visible
          }
        }
      }
    }
  }
`;

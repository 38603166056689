import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

const PostHeader = styled.header`
`;

const PostTitle = styled.h3`
  font-size: 1.444rem;

  a {
    box-shadow: none;
  }
`;

const PostMeta = styled.p`
  font-size: 0.8rem;
  color: hsla(0, 0%, 0%, 0.7);
  margin: 0;
  margin-top: -1.2rem;
`;

const PostExcerpt = styled.p`
  font-size: 1rem;
  color: hsla(0, 0%, 0%, 0.8);
  margin: 0;
  margin-top: 0.833rem;
`;

const Post = styled.article`
  display: block;

  & + & {
    padding-top: 1.666rem;
    margin-top: 1.666rem;
    border-top: 2px solid hsla(0, 0%, 0%, 0.1);
  }
`;

const ListItem = ({ postNode }) => {
  const {
    frontmatter: {
      title,
      date,
      excerpt,
      url,
    },
    fields: {
      slug,
    },
  } = postNode;

  return (
    <Post>
      <PostHeader>
        <PostTitle>
          { url ? (
            <a href={url} title={title} target="_blank" rel="noopener noreferrer">
              { title }
            </a>
          ) : (
            <Link to={`/blog/${slug}/`} title={title}>
              { title }
            </Link>
          )}
        </PostTitle>
        <PostMeta>
          Posted on { date }
        </PostMeta>
        <PostExcerpt>
          { excerpt }
        </PostExcerpt>
      </PostHeader>
    </Post>
  );
};

export default ListItem;

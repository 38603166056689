import React from 'react';
import styled from '@emotion/styled';

import ListItem from './listItem';

const StyledList = styled.div`
`;

const List = ({ posts }) => {
  return (
    <StyledList>
      {posts.edges.map(post => {
        return <ListItem key={post.node.id} postNode={post.node} />;
      })}
    </StyledList>
  );
};

export default List
